import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from 'fetcher-session';
import React from 'react';
import { Button } from 'ui';
import { postTakeDownItem } from '~/api/item';
import ErrorMessage from '~/components/Form/ErrorMessage';
import { RemoveIcon } from '~/public/static/svg/ItemActionIcons';

interface Props {
  itemId: number;
}

const TakeDownButton: React.FC<Props> = ({ itemId }) => {
  const queryClient = useQueryClient();
  const {
    error,
    isLoading,
    mutate: takeDownItem,
  } = useMutation(() => postTakeDownItem(itemId), {
    onSuccess: () => queryClient.invalidateQueries([itemId]),
  });

  return (
    <>
      <Button
        color="danger"
        className="flex flex-1 items-center justify-center"
        onClick={() => takeDownItem()}
        disabled={isLoading}
        variant="outlined"
      >
        <RemoveIcon fill="#e84f4f" className="mr-1" /> Take Down
      </Button>

      <ErrorMessage errors={error as ApiError} />
    </>
  );
};

export default TakeDownButton;
