import React from 'react';
import { Button } from 'ui';
import { endVacation } from '~/api/user';
import Image from '~/components/Image';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import BIGBOI from '~/public/static/lockers/bigcat.jpg';
import createSvg from '~/public/static/svg/createSvg';
import bigCatUserId from '../../constants/bigCatUserId';

interface Props {}

const LockerEmptyState: React.FC<Props> = () => {
  const { data: user } = useLockerUser();
  const myLocker = useIsMyLocker();
  const activeVacation = user?.active_vacation;

  if (user?.id === bigCatUserId) {
    return (
      <div className="mx-auto max-w-lg text-center">
        <Image src={BIGBOI} className="mx-auto mb-4" />
      </div>
    );
  }

  if (activeVacation) {
    return (
      <div className="text-center">
        <div className="mb-2 text-2xl">
          {myLocker
            ? "You're in Vacation Mode"
            : `@${user.username} is on vacation`}
        </div>

        <div className="mb-4 text-base">
          Items are currently unavailable for sale
        </div>

        <VacationModeIcon className="mx-auto mb-4 mt-6 block" />

        {myLocker && (
          <Button
            onClick={async () => {
              await endVacation();
              window.location.reload();
            }}
          >
            Turn OFF Vacation Mode
          </Button>
        )}
      </div>
    );
  }

  return <h1 className="my-4 text-center">No items</h1>;
};

const VacationModeIcon = createSvg(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="159"
    height="159"
    viewBox="0 0 159 159"
    {...props}
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFCE54"
        d="M89.596 100.09c0 14.546 11.792 26.338 26.338 26.338 14.544 0 26.336-11.792 26.336-26.337s-11.792-26.337-26.336-26.337c-14.54.016-26.322 11.798-26.338 26.337z"
      />
      <path
        fill="#D8D8D8"
        d="M100.884 152.765h30.101v5.267h-30.1zM89.596 142.23h52.675v5.267H89.596z"
      />
      <path
        fill="#B7B7B7"
        d="M31.655 142.23h5.268v5.267h-5.268zM15.854 147.497h5.266v5.268h-5.266z"
      />
      <path
        fill="#B7B7B7"
        d="M31.919 127.41a199.468 199.468 0 0 0 25.338-77.957l24.494 24.348a2.63 2.63 0 0 0 2.27.732 2.63 2.63 0 0 0 1.93-1.421 25.711 25.711 0 0 0-4.825-29.716l-2.154-2.155h18.525a2.633 2.633 0 0 0 2.5-3.468 25.714 25.714 0 0 0-24.426-17.601h-3.423l13.153-13.23a2.633 2.633 0 0 0-.69-4.213A25.72 25.72 0 0 0 54.88 7.55l-2.515 2.515a25.758 25.758 0 0 0-2.283 2.634 25.306 25.306 0 0 0-2.263-2.634l-2.515-2.515A25.708 25.708 0 0 0 15.59 2.73a2.633 2.633 0 0 0-.69 4.213l13.168 13.23h-3.457a25.713 25.713 0 0 0-24.426 17.6 2.633 2.633 0 0 0 2.5 3.469h18.507l-2.154 2.155a25.713 25.713 0 0 0-4.826 29.716 2.633 2.633 0 0 0 4.214.69l22.809-22.675a142.746 142.746 0 0 1-27.536 69.604 98.27 98.27 0 0 0-10.75-2.191l-1.646-.166-.527 5.244 1.535.15c2.634.377 5.25.909 7.854 1.511-.527.661-1.054 1.333-1.6 1.984l4.04 3.379A148.278 148.278 0 0 0 47.06 45.334l3.032-3.013 2.27 2.257a192.562 192.562 0 0 1-33.398 93.496l4.313 3.019c2.071-2.956 4.046-6.024 5.96-9.15a93.414 93.414 0 0 1 31.868 24.967l4.074-3.337a98.374 98.374 0 0 0-17.49-16.61h110.383v-5.268H39.886c-2.582-1.559-5.246-2.971-7.967-4.285zm50.708-60.17L54.801 39.574a20.315 20.315 0 0 1 20.105 5.03l2.515 2.516a20.448 20.448 0 0 1 5.206 20.12zM93.48 35.974H71.672a25.685 25.685 0 0 0-16.314-1.946 20.424 20.424 0 0 1 16.649-8.589h3.555a20.449 20.449 0 0 1 17.917 10.535zm-37.4-22.184l2.517-2.515a20.463 20.463 0 0 1 20.131-5.207L63.115 21.773A25.688 25.688 0 0 0 50.68 31.53a19.935 19.935 0 0 1 5.407-17.74h-.008zm-14.508-2.515l2.515 2.515a19.989 19.989 0 0 1 3.03 3.99 25.058 25.058 0 0 0-2.013 8.755 25.691 25.691 0 0 0-8.032-4.762L21.455 6.068a20.454 20.454 0 0 1 20.124 5.207h-.008zM24.602 25.44h3.556a20.43 20.43 0 0 1 16.666 8.585 25.696 25.696 0 0 0-16.305 1.95H6.703A20.451 20.451 0 0 1 24.611 25.44h-.009zM17.555 67.24a20.449 20.449 0 0 1 5.207-20.12l2.515-2.516a20.13 20.13 0 0 1 14.28-5.878c1.975.001 3.94.283 5.836.837L17.555 67.24z"
      />
    </g>
  </svg>
));

export default LockerEmptyState;
