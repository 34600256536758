import React from 'react';
import LockerItemTableRow from '~/components/ItemThumbnail/ItemTable/LockerItemTableRow';
import LockerItemCheckbox from '~/components/NewLockers/BulkActions/LockerItemCheckbox';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  items: RailsThumbnailItem[];
  onInvalidate?: () => void;

  isSelectingAll?: boolean;
  selecting?: boolean;
  selectedItems?: number[];
  onSelect?: (item: RailsThumbnailItem) => void;
}

const LockerItemTable: React.FC<Props> = ({
  items,
  onInvalidate,
  isSelectingAll,
  selecting,
  selectedItems,
  onSelect,
}) => {
  const colCx =
    'px-6 py-3 text-xs font-medium tracking-wider text-left uppercase bg-pastel-green-500';

  return (
    <div className="">
      <table className="min-w-full divide-y rounded">
        <thead className="bg-grey-200">
          <tr>
            {selecting && <th scope="col" className={colCx} />}
            <th scope="col" className={colCx}>
              Item
            </th>
            <th scope="col" className={colCx}>
              Price
            </th>
            <th scope="col" className={colCx}>
              Info
            </th>
            <th scope="col" className={colCx}>
              Promote
            </th>
            <th scope="col" className={colCx}>
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {items.map(item => (
            <tr key={item.id}>
              {selecting && (
                <td className="pl-4">
                  <LockerItemCheckbox
                    itemId={item.id}
                    disabled={isSelectingAll}
                    checked={
                      isSelectingAll || !!selectedItems?.includes(item.id)
                    }
                    onChange={() => onSelect?.(item)}
                  />
                </td>
              )}

              <LockerItemTableRow item={item} onInvalidate={onInvalidate} />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LockerItemTable;
