'use client';

import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import SimpleFade from '~/components/Transitions/SimpleFade';
import useShareSheet from '~/hooks/useShareSheet';
import { RailsItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsItem;
}

const ItemDetailsItemName: React.FC<Props> = ({ item }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (copiedToClipboard) {
      const timeout = setTimeout(() => setCopiedToClipboard(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [copiedToClipboard]);

  const onShare = useShareSheet(
    {
      text: item.name,
      title: 'View on SidelineSwap',
      url: item.url,
    },
    item.url,
    () => setCopiedToClipboard(true),
  );

  const shareButton = (
    <span className="relative md:hidden" aria-hidden>
      <button onClick={onShare}>
        <ArrowUpOnSquareIcon className="h-6 w-6" />
      </button>

      <SimpleFade show={copiedToClipboard}>
        <div className="absolute right-0 top-4 mt-2 whitespace-nowrap text-sm">
          URL copied to clipboard
        </div>
      </SimpleFade>
    </span>
  );

  return (
    <h1 className="mb-2 text-xl md:text-2xl">
      {item.name} {shareButton}
    </h1>
  );
};

export default ItemDetailsItemName;
