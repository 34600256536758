'use client';

import { clsx } from 'clsx';
import React, { useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import SwipeableViews from 'react-swipeable-views';
import { useKey, useWindowSize } from 'react-use';
import Image, { generateFastlyImageUrl } from '~/components/Image';
import {
  CarouselNextButton,
  CarouselPreviousButton,
} from '~/components/ItemDetails/elements';
import useCarousel from '~/hooks/useCarousel';
import { RailsImage } from '~/typings/services/rails/item';

interface Props {
  disableZooming?: boolean;
  images: RailsImage[];
  enlargedContainerDimensions?: { width: string; height: string };
  heights?: [number, number, number];
  sizes?: [number, number, number];
  thumbnailLimit?: number;
}

const largeSizes = [1000, 1500, 2000];

const DesktopCarousel: React.FC<Props> = ({
  disableZooming,
  images,
  enlargedContainerDimensions = { width: '100%', height: '100%' },
  heights = [420, 560, 600],
  sizes = [360, 488, 800],
  thumbnailLimit,
}) => {
  const { index, goToNextIndex, goToPreviousIndex, setIndex } = useCarousel(
    images.length,
  );

  useEffect(() => {
    setIndex(0);
  }, [images]);

  useKey('ArrowRight', goToNextIndex);
  useKey('ArrowLeft', goToPreviousIndex);

  const { width: windowWidth } = useWindowSize();
  const sizeIndex = windowWidth > 1280 ? 2 : windowWidth > 1024 ? 1 : 0;

  const smallSize = sizes[sizeIndex];
  const largeSize = largeSizes[sizeIndex];
  const heightSize = heights[sizeIndex];

  return (
    <>
      <div className="relative">
        <SwipeableViews
          index={index}
          onChangeIndex={i => setIndex(i)}
          slideStyle={{
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: '#fff',
            height: 600,
          }}
        >
          {images.map((image, i) => {
            const smallSrc = generateFastlyImageUrl(image.large_url, {
              height: 600,
            });
            const largeSrc = generateFastlyImageUrl(image.large_url, {
              height: 800,
            });
            return index % images.length === i && !disableZooming ? (
              <React.Fragment key={image.id}>
                <ReactImageMagnify
                  smallImage={{
                    src: smallSrc,
                    isFluidWidth: true,
                    height: heightSize,
                  }}
                  largeImage={{
                    src: largeSrc,
                    width: largeSize,
                    height: largeSize,
                  }}
                  imageStyle={{
                    maxWidth: 'none',
                    objectFit: 'contain',
                    maxHeight: heightSize,
                  }}
                  // enlargedImageContainerClassName="shadow-2xl"
                  enlargedImageStyle={{
                    objectFit: 'contain',
                    backgroundColor: '#fff',
                    maxWidth: 'none',
                    padding: 128,
                  }}
                  enlargedImagePosition="over"
                  // enlargedImagePortalId={`desktop-carousel-portal-${item.id}`}
                  lensStyle={{ backgroundColor: 'rgba(0,0,0,.6)' }}
                  enlargedImageContainerDimensions={enlargedContainerDimensions}
                  fadeDurationInMs={200}
                />
                {/* Use this to preload the large image */}
                <img
                  src={largeSrc}
                  style={{
                    position: 'absolute',
                    zIndex: -1,
                    left: -1000,
                  }}
                />
              </React.Fragment>
            ) : (
              <Image
                key={image.id}
                src={smallSrc}
                loading={i === 0 ? 'eager' : 'lazy'}
                height={heightSize}
                width={smallSize}
                style={{
                  maxHeight: heightSize,
                  objectFit: 'contain',
                  maxWidth: thumbnailLimit ? smallSize : 'auto',
                }}
              />
            );
          })}
        </SwipeableViews>

        <CarouselPreviousButton onClick={goToPreviousIndex} />
        <CarouselNextButton onClick={goToNextIndex} />
      </div>

      <div className="mt-4 flex flex-wrap justify-center">
        {(thumbnailLimit ? images.slice(0, thumbnailLimit) : images).map(
          (image, i) => (
            <button
              key={image.id}
              onClick={() => setIndex(i)}
              className="mb-2 mr-2"
            >
              <Image
                useFastly={{
                  height: 56,
                  width: 56,
                  crop: '1:1',
                }}
                src={image.large_url}
                height={56}
                width={56}
                className={clsx(
                  'rounded object-cover transition-opacity duration-300',
                  i !== index && 'opacity-25',
                )}
              />
            </button>
          ),
        )}
      </div>
    </>
  );
};

export default DesktopCarousel;
