import { useMobile } from 'common-nextjs';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import Image from '~/components/Image';
import { getImageUrl } from '~/components/ItemThumbnail/ItemThumbnailImage';
import SimpleFade from '~/components/Transitions/SimpleFade';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsThumbnailItem;
}

const HoverableTableImage: React.FC<Props> = ({ item }) => {
  const mobile = useMobile();
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const [enlargedImageShown, setEnlargedImageShown] = useState(false);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'right',
      strategy: 'fixed',
    },
  );

  return (
    <>
      <div className="relative flex-shrink-0">
        <div
          onMouseEnter={() => setEnlargedImageShown(true)}
          onMouseLeave={() => setEnlargedImageShown(false)}
          ref={setReferenceElement}
        >
          <Image
            className="h-14 w-14 rounded object-cover"
            src={getImageUrl(item, mobile)}
            useFastly={{
              crop: '1:1',
              height: 300,
              width: 300,
            }}
          />
        </div>
      </div>

      <SimpleFade show={enlargedImageShown}>
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="pointer-events-none"
        >
          <Image
            src={item.primary_image?.large_url}
            style={{
              width: 400,
            }}
            className="rounded"
            onLoadedData={() => update?.()}
            onLoad={() => update?.()}
          />
        </div>
      </SimpleFade>
    </>
  );
};

export default HoverableTableImage;
