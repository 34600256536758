import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useMobile } from 'common-nextjs';
import { formatDistanceToNow } from 'date-fns';
import { useRouter } from 'next/router';
import React from 'react';
import { LegacyButtonLink, Loader, Message } from 'ui';
import { displayPrice, qsUpdateString } from 'utils';
import { fetchItemById } from '~/api/item';
import { fetchLockerItemsNoCache } from '~/api/locker';
import ErrorMessage from '~/components/Form/ErrorMessage';
import DesktopCarousel from '~/components/ItemDetails/DesktopCarousel';
import FreeShippingItemDetailsPill from '~/components/ItemDetails/FreeShippingItemDetailsPill';
import ItemActionButtons from '~/components/ItemDetails/ItemActionButtons';
import ItemDescription from '~/components/ItemDetails/ItemDescription';
import ItemDetailsItemName from '~/components/ItemDetails/ItemDetailsItemName';
import PrivateDetails from '~/components/ItemDetails/PrivateDetails';
import ItemLabelPill from '~/components/ItemThumbnail/ItemLabelPill';
import LockerLink from '~/components/Links/LockerLink';
import NewTabLink from '~/components/Links/NewTabLink';
import SeeAllFilters from '~/components/NewLockers/SeeAllFilters';
import ProItemSwapInsights from '~/components/SidelinePro/Insights/ProItemSwapInsights';
import { FacetFilteringProvider } from '~/contexts/facetFiltering/FacetFilteringContext';
import flattenFilterState from '~/contexts/facetFiltering/flattenFilterState';
import { useListFlowDetails } from '~/hooks/listFlow/useListFlowDetails';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';
import { LockerRouteQuery } from '~/pages/locker/[username]/[itemState]';
import { EditIcon } from '~/public/static/svg/ItemActionIcons';

/*
params.coaching could be "1". in that case, we're in coaching mode,
but find the first ID from facetResponse and shallow push to it.
 */

const PAGE_SIZE = 20;

interface Props {}

const LockerCoachingModePage: React.FC<Props> = () => {
  const mobile = useMobile();
  const router = useRouter();
  const isMyLocker = useIsMyLocker();
  const { itemState, username } = router.query as LockerRouteQuery;
  const { coaching, coaching_index, ...queryRest } =
    useBrowserRouteQuery<LockerRouteQuery>();

  const itemIndex = Number(coaching_index || 0);
  const page = Math.floor(itemIndex / PAGE_SIZE + 1);

  const { data: facetResponse, isLoading: isLoadingItemsResponse } = useQuery(
    ['lockerItems', { ...queryRest, page, username }, isMyLocker],
    () =>
      fetchLockerItemsNoCache({
        ...queryRest,
        page,
        context: isMyLocker ? 'locker_owner' : 'locker_visitor',
        itemState,
        username,
        page_size: PAGE_SIZE,
      }),
    {
      refetchOnMount: false,
      staleTime: 30000,
    },
  );

  const itemId = Number(facetResponse?.data?.[itemIndex % PAGE_SIZE]?.id || 0);

  const {
    data: item,
    isLoading: isLoadingItem,
    error,
  } = useQuery(['items', itemId], () => fetchItemById(itemId), {
    enabled: !!itemId,
  });
  const { data: itemCategoryDetails, isLoading: isLoadingCategoryDetails } =
    useListFlowDetails(item?.categories?.[0]?.id);

  function goToNextItem() {
    if (!facetResponse?.data || itemIndex == null) {
      return;
    }

    if (itemIndex + 1 < facetResponse?.meta?.paging?.total_count) {
      const newQuery = qsUpdateString([
        router.asPath.split('?')[1],
        {
          coaching: '1',
          coaching_index: itemIndex + 1,
        },
      ]);

      router.push(
        itemState
          ? `/locker/${username}/${itemState}${newQuery}`
          : `/locker/${username}${newQuery}`,
        undefined,
        {
          shallow: true,
        },
      );
    }
  }

  function goToPrevItem() {
    if (!facetResponse?.data || itemIndex == null) {
      return;
    }

    // Only go to the previous item if we're not on the first item
    if (itemIndex > 0) {
      const newQuery = qsUpdateString([
        router.asPath.split('?')[1],
        {
          coaching: '1',
          coaching_index: itemIndex - 1,
        },
      ]);

      router.push(
        itemState
          ? `/locker/${username}/${itemState}${newQuery}`
          : `/locker/${username}${newQuery}`,
        undefined,
        {
          shallow: true,
        },
      );
    }
  }

  if (isLoadingItemsResponse) {
    return <Loader loading />;
  }

  if (!facetResponse) {
    return <Message error>Something went wrong fetching your locker</Message>;
  }

  // If this is condition is set, can we still enable page next/prev? It may resolve the issue
  if (itemIndex == -1 || itemIndex == null) {
    return (
      <Message
        warning
        action={{
          onClick: goToNextItem,
          label: 'Attempt a Fix',
        }}
      >
        Sorry, Nick A isn&apos;t smart enough to deal with this case. Can you
        let him know? You may have to restart coaching mode. Reloading the page
        may fix it.
        <br />
        It&apos;s possible this happens when items were put into the{' '}
        <code>{itemState || 'available'}</code> state after you started coaching
        mode.
      </Message>
    );
  }

  const topRow = (
    <div id="top-row" className="mb-4 flex items-center gap-4">
      <button
        onClick={goToPrevItem}
        className="disabled:text-slate-green-500 relative"
        disabled={itemIndex <= 0}
      >
        <span className="absolute -inset-3" />
        <ChevronLeftIcon className="h-4 w-4" />
      </button>
      <span>
        {itemIndex + 1} of {facetResponse.meta.paging.total_count}
      </span>
      <button
        onClick={goToNextItem}
        className="disabled:text-slate-green-500 relative"
        disabled={facetResponse.meta.paging.total_count === itemIndex + 1}
      >
        <span className="absolute -inset-3" />
        <ChevronRightIcon className="h-4 w-4" />
      </button>

      <div className="flex-1" />

      <LockerLink username={username} itemState={itemState} query={queryRest}>
        <span className="text-sm underline">Back to Locker</span>
      </LockerLink>

      <SeeAllFilters />

      <LegacyButtonLink
        as={<NewTabLink href={`/items/${itemId}`} />}
        className="flex items-center gap-2"
      >
        <EditIcon className="h-5 w-5" />
        <span>Edit Item</span>
      </LegacyButtonLink>
    </div>
  );

  if (!item || isLoadingItem) {
    return (
      <div>
        {topRow}

        <Loader loading />

        <ErrorMessage errors={error} />
      </div>
    );
  }

  const margin = item.cost
    ? Math.round(((item.price - item.cost) / item.price) * 100)
    : null;

  return (
    <FacetFilteringProvider
      facets={facetResponse?.meta.facets || []}
      filterState={flattenFilterState(facetResponse?.meta.query)}
      paging={facetResponse?.meta.paging}
      type="locker"
    >
      {mobile && (
        <Message warning>Coaching mode may not look good on mobile</Message>
      )}

      {topRow}

      <ErrorMessage errors={error} />

      {isLoadingItem && <Loader loading />}

      <div id="item-section" className="mb-8">
        <div className="flex flex-col items-start gap-8 md:flex-row">
          <div className="flex-1">
            <DesktopCarousel images={item.images} />
          </div>

          <div id="item-details-section" className="md:w-1/2">
            <ItemDetailsItemName item={item} />

            <div className="mt-4 flex items-center gap-4">
              <h2>{displayPrice(item.price)}</h2>

              <span>Retail: {displayPrice(item.price_retail)}</span>

              <span>
                Cost: {displayPrice(item.cost)} ({margin}% margin)
              </span>

              {!!(item.published_at || item.created_at) && (
                <span>
                  Published{' '}
                  {formatDistanceToNow(
                    new Date(item.published_at || item.created_at),
                    {
                      addSuffix: true,
                    },
                  )}
                </span>
              )}
            </div>

            <div className="flex gap-4">
              <ProItemSwapInsights item={item}>Insights</ProItemSwapInsights>

              {item.url && (
                <NewTabLink href={item.url} className="text-sm underline">
                  Item Page
                </NewTabLink>
              )}
            </div>

            <div className="my-4 space-y-2">
              {item.label && (
                <div>
                  <ItemLabelPill
                    label={item.label}
                    auctionEndedAt={item.auction_ended_at}
                    auctionLiveCountdown
                  />
                </div>
              )}
              <FreeShippingItemDetailsPill item={item} />
            </div>

            <div className="my-6">
              <ItemActionButtons item={item} />
            </div>

            {isLoadingCategoryDetails ? (
              <Loader loading />
            ) : (
              <div className="my-6 grid grid-cols-2 gap-4 xl:grid-cols-3">
                <div>
                  <div className="font-semibold">Category</div>
                  <div>{item.categories?.[0]?.full_name}</div>
                </div>

                <div>
                  <div className="font-semibold">Brand</div>
                  <div>
                    {
                      item.details?.find(group => group.slug === 'brand')
                        ?.values?.[0]?.name
                    }
                  </div>
                </div>

                <div>
                  <div className="font-semibold">Model</div>
                  <div>{item.model?.name || <i>No model</i>}</div>
                </div>

                {itemCategoryDetails
                  ?.filter(
                    group =>
                      group.slug !== 'brand' && group.slug !== 'category',
                  )
                  .map(detailGroup => {
                    const selection = item.details?.find(
                      group => group.slug === detailGroup.slug,
                    );

                    return (
                      <div key={detailGroup.slug}>
                        <div className="font-semibold">{detailGroup.name}</div>
                        <div>
                          {selection?.values
                            ?.map(detail => detail.name)
                            ?.join(', ') || (
                            <span className="font-semibold text-red-500">
                              No {detailGroup.name}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}

            <div>
              <div className="font-semibold">Description</div>
              <ItemDescription item={item} />
            </div>

            <PrivateDetails item={item} />
          </div>
        </div>
      </div>
    </FacetFilteringProvider>
  );
};

export default LockerCoachingModePage;
