import React from 'react';
import ItemThumbnailGrid from '~/components/ItemGrids/ItemThumbnailGrid';
import ItemThumbnail from '~/components/ItemThumbnail/ItemThumbnail';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

export interface ItemThumbnailGridProps {
  items: RailsThumbnailItem[];
  onInvalidate?: () => void;
  src?: AnalyticsSource;
}

const ManagedItemThumbnailGrid: React.FC<ItemThumbnailGridProps> = ({
  items,
  ...props
}) => (
  <ItemThumbnailGrid>
    {items?.map((item, i) => (
      <ItemThumbnail key={item.id} item={item} position={i} {...props} />
    ))}
  </ItemThumbnailGrid>
);

export default ManagedItemThumbnailGrid;
