import { clsx } from 'clsx';
import React from 'react';
import { Button, LegacyButtonLink } from 'ui';
import ErrorMessage from '~/components/Form/ErrorMessage';
import CartLink from '~/components/Links/CartLink';
import { useSession } from '~/contexts/SessionContext';
import useAddToCart from '~/hooks/cart/useAddToCart';
import { RailsItem } from '~/typings/services/rails/item';

interface Props {
  className?: string;
  disabled?: boolean;
  item?: RailsItem;
  large?: boolean;
  primary?: boolean;
  selectedVariationId?: string;
}

const AddToCart: React.FC<Props> = ({
  className,
  disabled,
  item,
  primary,
  large = true,
  selectedVariationId,
}) => {
  const { inCart, onAddToCart, error } = useAddToCart(item);
  const { loggedIn } = useSession();

  const cx = clsx('block w-full', className);

  if (inCart) {
    return (
      <LegacyButtonLink
        as={<CartLink className="block" />}
        disabled={disabled}
        className={cx}
        size={large ? 'large' : 'normal'}
        id="view-in-cart"
        variant={primary ? 'contained' : 'outlined'}
      >
        View in Cart
      </LegacyButtonLink>
    );
  }

  if (!item) {
    return null;
  }

  if (item.state !== 'available' || !item.actions.includes('add_to_cart')) {
    return null;
  }

  if (!loggedIn && item.variations?.length! > 0) {
    return null;
  }

  const button = (
    <Button
      className={cx}
      disabled={disabled}
      variant={primary ? 'contained' : 'outlined'}
      onClick={() => onAddToCart(selectedVariationId)}
      size={large ? 'large' : 'normal'}
      id="add-to-cart"
    >
      Add to Cart
    </Button>
  );

  if (error) {
    return (
      <div>
        {button}
        <ErrorMessage errors={error} />
      </div>
    );
  }

  return button;
};

export default AddToCart;
