import { useQuery } from '@tanstack/react-query';
import { fetcher } from 'fetcher-session';
import React, { useState } from 'react';
import { Message } from 'ui';
import BulkAction from '~/components/NewLockers/BulkActions/BulkAction';
import useLockerPage from '~/hooks/lockers/useLockerPage';

interface Props {
  isSelectingAll: boolean;
  selectedItems: number[];
  totalItems: number;
}

const FloatingBulkActionBar: React.FC<Props> = ({
  isSelectingAll,
  selectedItems,
  totalItems,
}) => {
  const { username, itemState } = useLockerPage();
  const [message, setMessage] = useState<null | string>(null);
  const [messageType, setMessageType] = useState<null | string>(null);

  const { data: actions } = useQuery(['item-actions'], () => {
    return fetcher<{ label: string; slug: string }[]>('/v1/items/actions', {
      params: {
        state: itemState ?? 'available',
      },
    });
  });

  if (!actions) {
    return null;
  }

  return (
    <div className="fixed inset-x-0 bottom-8 z-10 flex items-center justify-center">
      <div className="rounded border bg-white px-4 py-2 shadow-lg">
        {message && (
          <Message
            success={messageType === 'success'}
            error={messageType === 'error'}
          >
            {message}
          </Message>
        )}

        <div className="flex items-center justify-center space-x-6">
          <span className="font-bold">
            {isSelectingAll ? totalItems : selectedItems.length}
          </span>

          {actions.length === 0 ? (
            <span>No actions available</span>
          ) : (
            actions.map(action => (
              <BulkAction
                key={action.slug}
                action={action}
                onSuccess={message => {
                  setMessage(message);
                  setMessageType('success');
                }}
                isSelectingAll={isSelectingAll}
                selectedItems={selectedItems}
                totalItemCount={
                  isSelectingAll ? totalItems : selectedItems.length || 0
                }
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default FloatingBulkActionBar;
