import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { thousandsSeparator } from 'utils';
import LockerLink from '~/components/Links/LockerLink';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerUser from '~/hooks/lockers/useLockerUser';
import useHasRailsSetting from '~/hooks/useHasRailsSetting';

interface Props {}

const LockerItemStates: React.FC<Props> = () => {
  const router = useRouter();

  const myLocker = useIsMyLocker();
  const { data: user } = useLockerUser();
  const isTradeInAccount = useHasRailsSetting('trade_in_account');

  if (!myLocker || !user) {
    return null;
  }

  const inactiveCx = 'pb-1 px-2 border-b-4 border-transparent';
  const activeCx = 'pb-1 px-2 text-black border-b-4 border-primary-500';

  return (
    <div className="text-slate-green-500 mb-4 mt-2 flex gap-4 text-center text-sm font-semibold md:text-left">
      <div className="flex-1">
        {isTradeInAccount && (
          <Link
            href={{
              query: {
                ...router.query,
                coaching: '1',
              },
            }}
            className="font-semibold text-green-500"
          >
            Enter Coaching Mode
          </Link>
        )}
      </div>

      {myLocker && (
        <LockerLink
          username={user.username}
          className={router.query.itemState === 'draft' ? activeCx : inactiveCx}
          itemState="draft"
        >
          Drafts ({thousandsSeparator(user.item_count.draft)})
        </LockerLink>
      )}
      <LockerLink
        username={user.username}
        className={
          router.route === '/locker/[username]' ||
          router.query.itemState == null
            ? activeCx
            : inactiveCx
        }
      >
        Available ({thousandsSeparator(user.item_count.available)})
      </LockerLink>

      {myLocker && (
        <LockerLink
          username={user.username}
          className={router.query.itemState === 'sold' ? activeCx : inactiveCx}
          itemState="sold"
        >
          Sold ({thousandsSeparator(user.feedback.count)})
        </LockerLink>
      )}

      {myLocker && (
        <LockerLink
          username={user.username}
          className={
            router.query.itemState === 'removed' ? activeCx : inactiveCx
          }
          itemState="removed"
        >
          Removed ({thousandsSeparator(user.item_count.removed)})
        </LockerLink>
      )}

      <div className="flex-1" />
    </div>
  );
};

export default LockerItemStates;
