import React from 'react';
import ItemEditOverrideButton from '~/components/ItemDetails/AdminActions/ItemEditOverrideButton';
import RemoveGmcButton from '~/components/ItemDetails/AdminActions/RemoveGmcButton';
import TakeDownButton from '~/components/ItemDetails/AdminActions/TakeDownButton';
import UnreportButton from '~/components/ItemDetails/AdminActions/UnreportButton';
import { RailsItem, RailsItemAction } from '~/typings/services/rails/item';

const ADMIN_ACCEPTED_ACTIONS: RailsItemAction[] = [
  'edit',
  'take_down',
  'unreport',
  'remove_gmc',
];

interface Props {
  item: RailsItem;
}

const ItemAdminActions: React.FC<Props> = ({ item }) => {
  const shouldShow = item.actions.some(action =>
    ADMIN_ACCEPTED_ACTIONS.includes(action),
  );

  if (!shouldShow) {
    return null;
  }

  const hasEdit = item.actions.includes('edit');
  const hasTakeDown = item.actions.includes('take_down');
  const hasUnreport = item.actions.includes('unreport');
  const hasRemoveGmc = item.actions.includes('remove_gmc');

  return (
    <>
      <div className="text-slate-green-500 mb-1 text-xs font-semibold">
        ADMIN ACTIONS
      </div>
      <div className="grid grid-cols-2 gap-2">
        {hasEdit && <ItemEditOverrideButton itemId={item.id} />}
        {hasTakeDown && <TakeDownButton itemId={item.id} />}
        {hasUnreport && <UnreportButton itemId={item.id} />}
        {hasRemoveGmc && <RemoveGmcButton itemId={item.id} />}
      </div>
    </>
  );
};

export default ItemAdminActions;
