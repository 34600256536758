import { useMutation } from '@tanstack/react-query';
import { fetcher, getErrorMessage } from 'fetcher-session';
import { useRouter } from 'next/router';
import React from 'react';
import { mapValues } from 'utils';
import BulkActionRemoveConfirmationModal from '~/components/NewLockers/BulkActions/BulkActionRemoveConfirmationModal';
import { useModals } from '~/contexts/ModalsContext';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';
import { useIsSidelineProAvailable } from '~/hooks/useIsSidelinePro';
import {
  BumpIcon,
  PriceDropIcon,
  PublishIcon,
  RemoveIcon,
  SaleTagIcon,
} from '~/public/static/svg/ItemActionIcons';
import { fireInitiatedBulkLockerAction } from '~/services/analytics/events/lockers';
import { LockerItemPageQuery } from '~/typings/pages/lockers';

function determinePayload(
  // ignore "select"
  { username, itemState, select: ignore, ...query }: LockerItemPageQuery,
  selectedItemsList?: number[],
  isSelectingAll?: boolean,
  additionalParameters?: Record<string, any>,
) {
  if (
    !isSelectingAll &&
    (!selectedItemsList || selectedItemsList.length === 0)
  ) {
    return;
  }

  if (isSelectingAll) {
    return {
      ...additionalParameters,
      query: {
        ...mapValues(query, (key, value) =>
          Array.isArray(value) ? value.map(Number) : value,
        ),
        seller: [username],
        state: itemState,
      },
    };
  }

  return {
    ...additionalParameters,
    query: {
      seller: [username],
      state: itemState,
      include_ids: selectedItemsList,
    },
  };
}

interface Props {
  action: {
    label: string;
    slug: string;
  };

  onSuccess?: (message: string) => void;
  onError?: (message: string) => void;

  isSelectingAll?: boolean;
  selectedItems?: number[];
  totalItemCount: number;
}

const BulkAction: React.FC<Props> = ({
  action,
  isSelectingAll,
  onSuccess,
  onError,
  selectedItems,
  totalItemCount,
}) => {
  const modals = useModals();
  const { username, itemState } = useRouter().query;
  const proAvailable = useIsSidelineProAvailable();
  const routeQuery = useBrowserRouteQuery() as LockerItemPageQuery;
  const query = {
    username,
    itemState,
    ...routeQuery,
  };

  const { mutate: bulkPublish } = useMutation(
    async () => {
      return fetcher<{ message: string }>('/v1/items/publish', {
        method: 'post',
        body: determinePayload(query, selectedItems, isSelectingAll),
        dataOnly: false,
      });
    },
    {
      onMutate: () =>
        fireInitiatedBulkLockerAction(action.slug, totalItemCount),
      onSuccess: d => onSuccess?.(d.message || 'Items will be published soon'),
      onError: e => onError?.(getErrorMessage(e)),
    },
  );

  const { mutate: bulkDiscard } = useMutation(
    async () => {
      return fetcher<{ message: string }>('/v1/items/discard', {
        method: 'post',
        body: determinePayload(query, selectedItems, isSelectingAll),
        dataOnly: false,
      });
    },
    {
      onMutate: () =>
        fireInitiatedBulkLockerAction(action.slug, totalItemCount),
      onSuccess: d => onSuccess?.(d.message || 'Items will be discarded soon'),
      onError: e => onError?.(getErrorMessage(e)),
    },
  );

  const { mutate: bulkRemove } = useMutation(
    async () => {
      return fetcher<{ message: string }>('/v1/items/remove', {
        method: 'post',
        body: determinePayload(query, selectedItems, isSelectingAll),
        dataOnly: false,
      });
    },
    {
      onMutate: () =>
        fireInitiatedBulkLockerAction(action.slug, totalItemCount),
      onSuccess: d => onSuccess?.(d.message || 'Items will be removed soon'),
      onError: e => onError?.(getErrorMessage(e)),
    },
  );

  const { mutate: bulkBump } = useMutation(
    async () => {
      if (proAvailable) {
        return fetcher<{ message: string }>('/v2/items/bump', {
          method: 'post',
          body: determinePayload(query, selectedItems, isSelectingAll),
          dataOnly: false,
        });
      }
      return fetcher<{ message: string }>('/v1/items/bump', {
        method: 'post',
        body: determinePayload(query, selectedItems, isSelectingAll),
        dataOnly: false,
      });
    },
    {
      onMutate: () =>
        fireInitiatedBulkLockerAction(action.slug, totalItemCount),
      onSuccess: d => onSuccess?.(d.message || 'Items will be bumped soon'),
      onError: e => onError?.(getErrorMessage(e)),
    },
  );

  const { mutate: bulkAddToSale } = useMutation(
    async () => {
      return fetcher<{ message: string }>('/v1/items/sale ', {
        method: 'post',
        body: determinePayload(query, selectedItems, isSelectingAll),
        dataOnly: false,
      });
    },
    {
      onMutate: () =>
        fireInitiatedBulkLockerAction(action.slug, totalItemCount),
      onSuccess: d => onSuccess?.(d.message || 'Items will be bumped soon'),
      onError: e => onError?.(getErrorMessage(e)),
    },
  );

  const { mutate: bulkPriceDrop } = useMutation(
    async (percentage: number) => {
      return fetcher<{ message: string }>('/v1/items/price_drop', {
        method: 'post',
        body: determinePayload(query, selectedItems, isSelectingAll, {
          price_percentage: percentage,
        }),
        dataOnly: false,
      });
    },
    {
      onMutate: () =>
        fireInitiatedBulkLockerAction(action.slug, totalItemCount),
      onSuccess: d => onSuccess?.(d.message || 'Items will be bumped soon'),
      onError: e => onError?.(getErrorMessage(e)),
    },
  );

  const handlePriceDropClick = () =>
    modals.openModal('priceDropBulk', {
      onDropPrice: percentage => bulkPriceDrop(percentage),
    });

  let icon: React.ReactNode = null;
  let actionFn: (() => void) | null = null;
  let isRemoveAction: boolean = false;

  switch (action.slug) {
    case 'publish':
      icon = <PublishIcon fill="currentColor" />;
      actionFn = bulkPublish;
      break;

    case 'discard':
      icon = <RemoveIcon fill="currentColor" />;
      actionFn = bulkDiscard;
      break;

    case 'sale':
      icon = <SaleTagIcon fill="currentColor" />;
      actionFn = bulkAddToSale;
      break;

    case 'remove':
      icon = <RemoveIcon fill="currentColor" />;
      actionFn = bulkRemove;
      isRemoveAction = true;
      break;

    case 'bump':
      icon = <BumpIcon fill="currentColor" />;
      actionFn = bulkBump;
      break;

    case 'price_drop':
      icon = <PriceDropIcon fill="currentColor" />;
      actionFn = handlePriceDropClick;
      break;
  }

  if (!actionFn) {
    return null;
  }

  if (isRemoveAction) {
    return (
      <BulkActionRemoveConfirmationModal
        mutate={actionFn}
        icon={icon}
        label={action.label}
        count={totalItemCount}
      />
    );
  }

  return (
    <button
      className="flex flex-col items-center justify-center space-y-1"
      onClick={actionFn}
    >
      {icon}
      <span className="text-xs font-semibold">{action.label}</span>
    </button>
  );
};

export default BulkAction;
