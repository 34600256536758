import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { Button } from 'ui';
import { displayPrice, pluralize } from 'utils';
import ErrorMessage from '~/components/Form/ErrorMessage';
import ItemFreeShippingPill from '~/components/ItemThumbnail/ItemFreeShippingPill';
import HoverableTableImage from '~/components/ItemThumbnail/ItemTable/HoverableTableImage';
import LockerItemTableActionMenu from '~/components/ItemThumbnail/ItemTable/LockerItemTableActionMenu';
import ItemDetailsLink from '~/components/Links/ItemDetailsLink';
import { useModals } from '~/contexts/ModalsContext';
import useBumpItem from '~/hooks/useBumpItem';
import useThumbnailMeta from '~/hooks/useThumbnailMeta';
import EyeIcon from '~/public/static/svg/EyeIcon';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsThumbnailItem;
  onInvalidate?: () => void;
}

const LockerItemTableRow: React.FC<Props> = ({ item, onInvalidate }) => {
  const modals = useModals();

  const {
    mutate: handleBump,
    isLoading: isBumping,
    error: bumpError,
  } = useBumpItem(item.id, onInvalidate);

  const handleClickPriceDrop: React.MouseEventHandler = e => {
    e.preventDefault();

    modals.openModal('priceDrop', {
      item,
      onSuccessfulSubmit: onInvalidate,
    });
  };

  const meta = useThumbnailMeta(item.id);

  return (
    <>
      {/* Item image and name */}
      <td className="px-4 py-2.5">
        <ItemDetailsLink
          item={item}
          className="flex items-center"
          prefetch={false}
        >
          <HoverableTableImage item={item} />

          {/* Item name column */}
          <div className="ml-4 space-y-2">
            <div className="line-clamp-3 text-sm font-medium">
              {item.name}
              {item.label ? (
                <>
                  {' - '}
                  <span
                    className="font-semibold"
                    style={{ color: item.label.color }}
                  >
                    {item.label.text}
                  </span>
                </>
              ) : null}
            </div>

            {meta?.shipping_label?.slug === 'free_shipping' && (
              <ItemFreeShippingPill />
            )}
          </div>
        </ItemDetailsLink>
      </td>

      {/* Price */}
      <td className="whitespace-nowrap px-4 py-2.5">
        <div>{displayPrice(item.price)}</div>
        <div className="text-slate-green-500 text-xs">
          Retail:{' '}
          {item.price_retail != null ? displayPrice(item.price_retail) : '$--'}
        </div>

        {!!meta?.inventory_id && (
          <div className="mt-2 text-right text-sm">
            Inventory ID: {meta.inventory_id}
          </div>
        )}

        {!!meta?.location_id && (
          <div className="mt-2 text-right text-sm">
            Location ID: {meta.location_id}
          </div>
        )}

        {/*<button className="mt-1 underline text-xs">
                  Compare Prices
                </button>*/}
      </td>

      {/* Info */}
      <td className="whitespace-nowrap px-4 py-2.5">
        <div className="text-slate-green-500 text-sm">
          <div className="flex items-center space-x-1">
            <EyeIcon className="h-4 w-4" fill="currentColor" />
            <span>
              {item.visit_count ?? 0}{' '}
              {pluralize(item.visit_count ?? 0, 'view', 'views')}
            </span>
          </div>
          <div className="flex items-center space-x-1">
            <FavoriteIcon className="h-4 w-4" fill="currentColor" />
            <span>
              {item.favoriters_count ?? 0}{' '}
              {pluralize(item.favoriters_count ?? 0, 'favorite', 'favorites')}
            </span>
          </div>

          {item.updated_at != null && (
            <div
              className="mt-1.5 text-xs"
              title={new Date(item.updated_at).toLocaleString()}
            >
              Last updated{' '}
              {formatDistanceToNow(new Date(item.updated_at), {
                addSuffix: true,
              })}
            </div>
          )}
        </div>
      </td>

      {/* Suggestions */}
      <td className="whitespace-nowrap px-4 py-2.5">
        <div className="flex items-center space-x-2">
          {meta?.actions.includes('bump') && (
            <Button
              size="small"
              variant="outlined"
              color="black"
              onClick={() => handleBump()}
              disabled={isBumping}
            >
              Bump
            </Button>
          )}

          {meta?.actions.includes('price_drop') && (
            <Button
              size="small"
              variant="outlined"
              color="black"
              onClick={handleClickPriceDrop}
            >
              Price Drop
            </Button>
          )}
        </div>

        <ErrorMessage
          errors={bumpError}
          style={{
            whiteSpace: 'break-spaces',
          }}
        />
      </td>

      {/* Actions */}
      <td className="whitespace-nowrap px-4 py-2.5">
        <LockerItemTableActionMenu item={item} onInvalidate={onInvalidate} />
      </td>
    </>
  );
};

export default LockerItemTableRow;
