import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { Panel } from 'ui';
import { displayPrice } from 'utils';
import ShopLink from '~/components/Links/ShopLink';
import ValueGuideLink from '~/components/Links/ValueGuideLink';
import { RailsItem } from '~/typings/services/rails/item';
import findFirstDetailId from '~/utils/findFirstDetailId';

const properties: Record<string | keyof RailsItem, React.ReactNode> = {
  seller_total_cost: 'My Cost',
  published_at: 'Item Age',
  gtin: 'GTIN',
  inventory_id: 'Inventory ID',
  last_bumped_at: 'Last Bumped',
  last_price_dropped_at: 'Last Price Dropped',
  location_id: 'Location ID',
  mpn: 'MPN',
  times_renewed: 'Times Renewed',
  views_count: 'Views',
};

const displayedProperties = Object.keys(properties);

const formatPrivateDetail = (property: keyof RailsItem, text: any, item) => {
  switch (property) {
    case 'seller_total_cost':
      return <MyCostRow item={item} />;

    case 'last_bumped_at':
      return new Date(text).toLocaleDateString();

    case 'last_price_dropped_at':
      return new Date(text).toLocaleDateString();

    case 'published_at':
      return formatDistanceToNow(new Date(text), {
        addSuffix: true,
      });

    default:
      return text;
  }
};

function PrivateDetails({ item }: { item: RailsItem }) {
  // If the item doesn't have at least one of the display-able properties, don't render anything
  if (!displayedProperties.some(property => !!item[property])) {
    return null;
  }

  return (
    <Panel className="mb-4">
      <h3 className="mb-2 text-base">Private Details</h3>
      <dl className="grid grid-cols-2 gap-2 text-sm">
        {displayedProperties.map(property => {
          const itemProp = item[property];
          if (!itemProp) return null;

          return (
            <React.Fragment key={property}>
              <dt className="font-semibold">{properties[property]}</dt>
              <dd>
                {formatPrivateDetail(
                  property as keyof RailsItem,
                  itemProp,
                  item,
                )}
              </dd>
            </React.Fragment>
          );
        })}
      </dl>

      <div className="mt-4 text-sm">
        Need help with pricing?{' '}
        {item.model ? (
          <ValueGuideLink
            className="text-blue-500"
            prefilledForm={{
              category: item.categories?.[0]?.id,
              model: item.model.id,
              brand: findFirstDetailId(item.details, 'brand'),
              condition: findFirstDetailId(item.details, 'condition'),
            }}
          >
            Check our value guide
          </ValueGuideLink>
        ) : (
          <ShopLink
            className="text-blue-500"
            path={item.categories?.[0]?.url}
            query={{ similar_to: item.id, recently_sold: 1 }}
          >
            View similar recently sold items
          </ShopLink>
        )}
      </div>
    </Panel>
  );
}

function MyCostRow({ item }: { item: RailsItem }) {
  return <>{displayPrice(item.seller_total_cost)} </>;
}

export default PrivateDetails;
