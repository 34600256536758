import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { Button } from 'ui';
import { qsUpdateString } from 'utils';
import PaginationSummary from '~/components/Pagination/PaginationSummary';
import { PaginationContainer } from '~/components/Pagination/elements';
import { RailsPaging } from '~/typings/services/rails/paging';

type Props = {
  className?: string;
  paging: RailsPaging;
};

const Paginator: React.FC<Props> = ({ className, paging }) => {
  const router = useRouter();
  const [path, query] = router.asPath.split('?');

  const prev =
    paging.page == 1
      ? null
      : qsUpdateString([
          query,
          {
            page: paging.page == 2 ? null : paging.page - 1,
          },
        ]);

  const next =
    (paging.total_pages != null && paging.page < paging.total_pages) ||
    paging.has_next_page
      ? qsUpdateString([
          query,
          {
            page: paging.page + 1,
          },
        ])
      : null;

  const prevButton = (
    <Button variant="outlined" size="tiny" disabled={prev == null}>
      Prev
    </Button>
  );

  const nextButton = (
    <Button variant="outlined" size="tiny" disabled={next == null}>
      Next
    </Button>
  );

  return (
    <PaginationContainer className={className}>
      <PaginationSummary paging={paging} />

      {typeof prev === 'string' ? (
        <Link href={router.route + prev} as={path + prev} rel="prev">
          {prevButton}
        </Link>
      ) : (
        prevButton
      )}

      {typeof next === 'string' ? (
        <Link href={router.route + next} as={path + next} rel="next">
          {nextButton}
        </Link>
      ) : (
        nextButton
      )}
    </PaginationContainer>
  );
};

export default Paginator;
