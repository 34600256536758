export function mapValues<T extends { [key: string]: any }>(
  object: T,
  mapper: (key: keyof T, value: T[keyof T]) => any,
) {
  const entries = Object.entries(object);

  return entries.reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: mapper(key, value),
    }),
    {},
  );
}
