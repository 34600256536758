import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import React from 'react';
import { Button } from 'ui';
import HorizontalDots from '~/components/Icons/HorizontalDots';
import ItemThumbnailOverlayActions from '~/components/ItemThumbnail/ActionsOverlay/ItemThumbnailOverlayActions';
import useThumbnailMeta from '~/hooks/useThumbnailMeta';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsThumbnailItem;
  onInvalidate?: () => void;
}

const LockerItemTableActionMenu: React.FC<Props> = ({ item, onInvalidate }) => {
  const meta = useThumbnailMeta(item.id);

  return (
    <>
      <Popover as="div" className="relative inline-block text-left">
        <div>
          <PopoverButton
            as={Button}
            variant="outlined"
            size="small"
            color="black"
          >
            <HorizontalDots />
          </PopoverButton>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <PopoverPanel className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded bg-white shadow-lg focus:outline-none">
            <ItemThumbnailOverlayActions
              item={item}
              meta={meta}
              onInvalidate={onInvalidate}
              buttonClassName="hover:bg-pastel-green-500 px-4 w-full"
              messageClassName="mx-4 my-2"
            />
          </PopoverPanel>
        </Transition>
      </Popover>
    </>
  );
};

export default LockerItemTableActionMenu;
