import { SyntheticEvent, useCallback } from 'react';

type ShareOptions = { title: string; text: string; url: string };

type NavigatorShare = (options: ShareOptions) => Promise<{}>;

interface NavigatorOverride {
  share?: NavigatorShare;
}

export default function useShareSheet(
  shareOptions: ShareOptions,
  clipboardText: string,
  onCopyToClipboard?: () => void,
) {
  return useCallback(
    async (e: SyntheticEvent) => {
      if ((window.navigator as unknown as NavigatorOverride).share) {
        e.preventDefault();
        e.stopPropagation();

        try {
          await (window.navigator as unknown as NavigatorOverride).share!(
            shareOptions,
          );
        } catch (e) {
          // I can capture "Share cancelled" here
        }
        return;
      }

      if (navigator.clipboard && navigator.permissions) {
        try {
          const chromePermission = await navigator.permissions.query({
            // @ts-ignore
            name: 'clipboard-write',
          });

          if (
            chromePermission.state === 'granted' ||
            chromePermission.state === 'prompt'
          ) {
            await navigator.clipboard.writeText(clipboardText);
            onCopyToClipboard?.();
          } else {
            document.querySelector<HTMLInputElement>('#hidden-text')?.select();
            document.execCommand('copy');
            onCopyToClipboard?.();
          }
        } catch (e) {
          try {
            const firefoxPermission = await navigator.permissions.query({
              // @ts-ignore
              name: 'clipboard',
            });

            if (
              firefoxPermission.state === 'granted' ||
              firefoxPermission.state === 'prompt'
            ) {
              await navigator.clipboard.writeText(clipboardText);
              onCopyToClipboard?.();
            } else {
              document
                .querySelector<HTMLInputElement>('#hidden-text')
                ?.select();
              document.execCommand('copy');
              onCopyToClipboard?.();
            }
          } catch (e) {
            document.querySelector<HTMLInputElement>('#hidden-text')?.select();
            document.execCommand('copy');
            onCopyToClipboard?.();
          }
        }
      }
    },
    [shareOptions, clipboardText, onCopyToClipboard],
  );
}
