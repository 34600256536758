import { CSSProperties } from 'react';
import { Button } from 'ui';
import useItemFavorite from '~/hooks/useItemFavorite';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { RailsItem } from '~/typings/services/rails/item';

function ItemFavoriteButton({
  item,
  showLabel = true,
  style,
}: {
  item: RailsItem;
  showLabel?: boolean;
  style?: CSSProperties;
}) {
  const { favorite, favorited, favoritersCount } = useItemFavorite(item);

  return (
    <Button
      variant="outlined"
      className="flex items-center justify-center"
      onClick={favorite}
      id="favorite-button"
      style={style}
    >
      <div className="mr-1">{favoritersCount}</div>

      <FavoriteIcon
        active={favorited}
        className={favorited ? 'active mr-1' : 'mr-1'}
      />

      {showLabel && <div>{favorited ? 'Favorited' : 'Favorite'}</div>}
    </Button>
  );
}

export default ItemFavoriteButton;
