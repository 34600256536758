import React from 'react';
import { Checkbox } from 'ui';

interface Props {
  checked: boolean;
  disabled?: boolean;
  itemId: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const LockerItemCheckbox: React.FC<Props> = ({
  checked,
  disabled,
  itemId,
  onChange,
}) => (
  <Checkbox
    className="h-6 w-6"
    disabled={disabled}
    value={itemId}
    checked={checked}
    onChange={onChange}
  />
);

export default LockerItemCheckbox;
