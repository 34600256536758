import React, { useMemo } from 'react';
import { thousandsSeparator } from 'utils';
import { RailsPaging } from '~/typings/services/rails/paging';

interface Props {
  paging: RailsPaging;
}

const PaginationSummary: React.FC<Props> = ({ paging }) => {
  const summaryText = useMemo(() => {
    const {
      page,
      page_size: pageSize,
      total_count: totalCount,
      total_pages: totalPages,
    } = paging;

    let start;
    let pageEnd;

    if (page == 1) {
      start = 1;
    }

    if (page > 1) {
      start = (page - 1) * pageSize;
    }

    if (page === totalPages) {
      pageEnd = totalCount;
    } else {
      pageEnd = page * pageSize;
    }

    if (totalCount != null) {
      return `${start} - ${pageEnd} of ${thousandsSeparator(totalCount)}`;
    }

    return `${start} - ${pageEnd}`;
  }, [paging]);

  return <div className="font-semibold">{summaryText}</div>;
};

export default PaginationSummary;
