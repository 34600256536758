import { useMobile } from 'common-nextjs';
import React from 'react';
import { Button } from 'ui';
import FilterModalBottomButton from '~/components/Search/FilterModal/FilterModalBottomButton';
import FilterModalTitle from '~/components/Search/FilterModal/FilterModalTitle';
import UniqueFilteringModal from '~/components/Search/FilterModal/UniqueFilteringModal';
import FilteringList from '~/components/Search/FilteringList/FilteringList';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import FilterIcon from '~/public/static/svg/FilterIcon';
import { useAppDispatch, useAppSelector } from '~/store/store';
import { isFilterModalOpen, setFilterModalOpen } from '~/store/ui/uiSlice';

interface Props {}

const SeeAllFilters: React.FC<Props> = () => {
  const mobile = useMobile();
  const facetProps = useFacetFiltering();

  const open = useAppSelector(isFilterModalOpen);
  const dispatch = useAppDispatch();

  const openModal = () => dispatch(setFilterModalOpen({ open: true }));
  const onRequestClose = () => dispatch(setFilterModalOpen({ open: false }));

  let button: React.ReactNode;

  if (mobile) {
    button = (
      <Button
        size="small"
        className="flex flex-shrink-0 items-center"
        variant="outlined"
        color="grey"
        onClick={openModal}
      >
        <FilterIcon fill="currentColor" className="mr-2" />
        Filter{facetProps.filterCount > 0 && ` (${facetProps.filterCount})`}
      </Button>
    );
  } else {
    button = (
      <Button
        onClick={openModal}
        className="flex flex-shrink-0 items-center"
        variant="outlined"
        color="grey"
      >
        <FilterIcon fill="currentColor" className="mr-2" /> Filter
        {facetProps.filterCount > 0 && ` (${facetProps.filterCount})`}
      </Button>
    );
  }

  return (
    <>
      {button}

      <UniqueFilteringModal
        onRequestClose={onRequestClose}
        open={open}
        direction={mobile ? undefined : 'left'}
      >
        <FilterModalTitle onClickApply={onRequestClose} />
        <div className="flex-1 px-4 py-3">
          {/* Forcibly show categories on lockers */}
          <FilteringList
            {...facetProps}
            showCategoryFilters={
              facetProps.type === 'locker' || facetProps.type === 'search'
            }
            showSort
          />
        </div>
        <FilterModalBottomButton onClick={onRequestClose} />
      </UniqueFilteringModal>
    </>
  );
};

export default SeeAllFilters;
