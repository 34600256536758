import React from 'react';
import { LegacyButtonLink } from 'ui';
import LockerLink from '~/components/Links/LockerLink';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useLockerPage from '~/hooks/lockers/useLockerPage';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';
import { EditIcon } from '~/public/static/svg/ItemActionIcons';

interface Props {}

const LockerSelectItems: React.FC<Props> = () => {
  const isMyLocker = useIsMyLocker();
  const { username, itemState } = useLockerPage();
  const query = useBrowserRouteQuery<{ select?: any }>();

  if (!isMyLocker) {
    return null;
  }

  if (query.select) {
    return (
      <LegacyButtonLink
        as={
          <LockerLink
            username={username}
            itemState={itemState}
            query={{ ...query, select: null }}
          />
        }
        variant="text"
      >
        Cancel
      </LegacyButtonLink>
    );
  }

  return (
    <LegacyButtonLink
      as={
        <LockerLink
          username={username}
          itemState={itemState}
          query={{ ...query, select: 1 }}
        />
      }
      variant="text"
      className="flex space-x-2"
    >
      <EditIcon />
      <span>Select Items</span>
    </LegacyButtonLink>
  );
};

export default LockerSelectItems;
