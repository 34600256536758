import React from 'react';
import { LegacyButtonLink } from 'ui';
import ListFlowLink from '~/components/Links/ListFlowLink';
import { EditIcon } from '~/public/static/svg/ItemActionIcons';

interface Props {
  itemId: number;
}

/**
 * For when you have the "edit" action, but aren't the item owner.
 */
const ItemEditOverrideButton: React.FC<Props> = ({ itemId }) => (
  <LegacyButtonLink
    as={<ListFlowLink className="block flex-1" itemId={itemId} />}
    color="black"
    className="flex w-full items-center justify-center"
    variant="outlined"
  >
    <EditIcon fill="currentColor" className="mr-1" /> Edit
  </LegacyButtonLink>
);

export default ItemEditOverrideButton;
