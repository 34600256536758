import fireAnalytics from '~/services/analytics/fireAnalytics';

export function fireInitiatedBulkLockerAction(
  actionSlug: string,
  count: number,
) {
  fireAnalytics('Initiated Bulk Locker Action', null, {
    count,
    action: actionSlug,
  });
}
