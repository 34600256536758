'use client';

import React, { useState } from 'react';
import ConfirmationModal, {
  ConfirmationModalProps,
} from '~/components/NewModal/ConfirmationModal';

export default function ManagedConfirmationModal({
  trigger,
  ...props
}: {
  trigger: React.ReactNode;
} & Omit<ConfirmationModalProps, 'open' | 'onClose'>) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const Trigger = React.Children.map(trigger, child =>
    React.cloneElement(child as React.ReactElement, {
      onClick: e => {
        if ((child as React.ReactElement)?.props?.onClick) {
          (child as React.ReactElement).props.onClick(e);
        }
        setOpen(true);
      },
    }),
  );

  return (
    <>
      {Trigger}

      <ConfirmationModal
        {...props}
        handleOK={async () => {
          try {
            const promise = props.handleOK?.();
            if (promise != null && 'then' in promise) {
              setLoading(true);
            }
            await promise;
            setOpen(false);
          } catch (e) {
            throw e;
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
