import { clsx } from 'clsx';
import React, { useRef } from 'react';
import { Button, ButtonProps, Loader } from 'ui';
import SimpleModal, {
  SimpleModalProps,
} from '~/components/NewModal/SimpleModal';

export interface ConfirmationModalProps
  extends Omit<SimpleModalProps, 'children'> {
  icon?: React.ReactNode;
  iconBackgroundClassName?: string; // This should be a color, like bg-red-100
  loading?: boolean;

  cancelButton?: React.ReactNode;
  noCancelButton?: boolean;
  handleOK?: () => void;
  handleCancel?: () => void;

  okText?: string;
  cancelText?: string;

  okProps?: ButtonProps;
  cancelProps?: ButtonProps;
}

const ConfirmationModal: React.FC<
  React.PropsWithChildren<ConfirmationModalProps>
> = ({
  children,
  description,
  iconBackgroundClassName,
  icon,
  loading,
  onClose,
  title,
  noCancelButton,
  open,
  cancelProps,
  cancelText,
  okText,
  okProps,
  handleCancel,
  handleOK,
}) => {
  const initialFocus = useRef<HTMLButtonElement>(null);

  const cancelButton = !noCancelButton && (
    <Button
      fluid
      variant="text"
      onClick={handleCancel ?? onClose}
      {...cancelProps}
    >
      {cancelText ?? 'Cancel'}
    </Button>
  );

  const confirmButton = (
    <Button fluid {...okProps} ref={initialFocus} onClick={handleOK ?? onClose}>
      {okText ?? 'OK'}
    </Button>
  );

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      className="w-full max-w-sm"
      title={title}
      description={description}
      initialFocus={initialFocus}
    >
      <div className="sm:flex sm:items-start">
        {icon && (
          <div
            className={clsx(
              'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
              iconBackgroundClassName,
            )}
          >
            {icon}
          </div>
        )}
      </div>

      {children}

      <Loader loading={loading}>
        <div className="mt-6 flex justify-end space-x-2">
          {cancelButton}
          {confirmButton}
        </div>
      </Loader>
    </SimpleModal>
  );
};

export default ConfirmationModal;
