import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Button, Message } from 'ui';
import { itemRemoveGmc } from '~/api/item';
import { NegativeFeedbackIcon } from '~/public/static/svg/FeedbackIcons';

interface Props {
  itemId: number;
}

const RemoveGmcButton: React.FC<Props> = ({ itemId }) => {
  const queryClient = useQueryClient();
  const { isLoading, isSuccess, mutate } = useMutation(
    () => itemRemoveGmc(itemId),
    {
      onSuccess: () => queryClient.invalidateQueries([itemId]),
    },
  );

  return (
    <div className="w-full">
      <Button
        className="flex h-10 w-full flex-1 items-center justify-center"
        onClick={() => mutate()}
        disabled={isLoading}
        variant="outlined"
        color="grey"
      >
        <NegativeFeedbackIcon className="mr-1 h-6 w-6 stroke-current" /> Remove
        GMC
      </Button>

      {isSuccess && (
        <Message success className="mt-2">
          Removed from GMC feed
        </Message>
      )}
    </div>
  );
};

export default RemoveGmcButton;
