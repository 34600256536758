'use client';

import { PrinterIcon } from '@heroicons/react/24/outline';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from 'fetcher-session';
import React, { useMemo } from 'react';
import { Button, LegacyButtonLink } from 'ui';
import {
  addItemToSale,
  postTakeDownItem,
  removeItemFromSale,
} from '~/api/item';
import ListFlowLink from '~/components/Links/ListFlowLink';
import NewTabLink from '~/components/Links/NewTabLink';
import SwapsInboxLink from '~/components/Links/SwapsInboxLink';
import ProItemSwapInsights from '~/components/SidelinePro/Insights/ProItemSwapInsights';
import { useModals } from '~/contexts/ModalsContext';
import { useSale } from '~/contexts/SaleContext';
import { useSession } from '~/contexts/SessionContext';
import useBumpItem from '~/hooks/useBumpItem';
import { dispatchAppDirRefreshFromLegacyEvent } from '~/lib/events/customEvents';
import {
  BumpIcon,
  DuplicateIcon,
  EditIcon,
  PriceDropIcon,
  RemoveIcon,
  SaleTagIcon,
  SwapsIcon,
} from '~/public/static/svg/ItemActionIcons';
import { RailsItem, RailsItemAction } from '~/typings/services/rails/item';
import ErrorMessage from '../Form/ErrorMessage';

interface Props {
  item: RailsItem;
}

const actionOrder: RailsItemAction[] = [
  'view_swap',
  'bump',
  'price_drop',
  'duplicate',
  'join_sale',
  'leave_sale',
  // 'send_offer',
  'view_swaps',
  'edit',
  'remove',
  'take_down',
];

const ItemOwnActions: React.FC<Props> = ({ item }) => {
  const sale = useSale();
  const session = useSession();
  const modals = useModals();
  const queryClient = useQueryClient();

  // This is some wacky JavaScript that filters the actionOrder array based on what's included in item.actions
  const availableActions = useMemo(
    () =>
      actionOrder.filter(function (
        this: RailsItemAction[],
        action: RailsItemAction,
      ) {
        return this.includes(action);
      }, item.actions),
    [item],
  );

  const buttonClasses = 'flex justify-center items-center gap-2';

  const reloadItem = () => {
    dispatchAppDirRefreshFromLegacyEvent();
    return queryClient.invalidateQueries({
      predicate: query => query.queryHash.includes(String(item.id)),
    });
  };

  const mutationOptions = {
    onSuccess: async () => {
      await reloadItem();
    },
  };

  const {
    error: takeDownError,
    status: takeDownStatus,
    mutate: takeDownItem,
  } = useMutation(() => postTakeDownItem(item.id), mutationOptions);

  const {
    error: bumpItemError,
    status: bumpItemStatus,
    mutate: bumpItem,
  } = useBumpItem(item.id, reloadItem);

  const {
    error: addToSaleError,
    status: addToSaleStatus,
    mutate: addToSale,
  } = useMutation(
    () => addItemToSale(item.id, sale.sale?.id!),
    mutationOptions,
  );

  const {
    error: removeFromSaleError,
    status: removeFromSaleStatus,
    mutate: removeFromSale,
  } = useMutation(
    () => removeItemFromSale(item.id, sale.sale?.id!),
    mutationOptions,
  );

  const additionalActions = (
    <>
      {availableActions.map(action => {
        // *******************
        // If you're adding an action here, be SURE to add it to be the above list of
        // actionOrder
        //*******************
        switch (action) {
          // case 'send_offer':
          //   return (
          //     <Button key={action} id={action} color="darkgrey" className={buttonClasses}>
          //       <div>{item.favoriters_count}</div>
          //       <FavoriteIcon className="mx-2" />
          //       <div>Send Offer</div>
          //     </Button>
          //   );

          case 'join_sale':
            return (
              <span>
                <Button
                  key={action}
                  id={action}
                  className={buttonClasses}
                  onClick={() => addToSale()}
                  disabled={addToSaleStatus === 'loading'}
                  variant="text"
                  size="small"
                >
                  <SaleTagIcon fill="currentColor" className="h-5 w-5" /> Add to
                  Sale
                </Button>
              </span>
            );

          case 'leave_sale':
            return (
              <span>
                <Button
                  key={action}
                  id={action}
                  className={buttonClasses}
                  onClick={() => removeFromSale()}
                  disabled={removeFromSaleStatus === 'loading'}
                  variant="text"
                  size="small"
                >
                  <SaleTagIcon fill="currentColor" className="h-5 w-5" /> Remove
                  from Sale
                </Button>
              </span>
            );

          case 'view_swaps':
            return (
              <LegacyButtonLink
                key={action}
                id={action}
                as={<SwapsInboxLink page="item" itemId={item.id} />}
                className={buttonClasses}
                variant="text"
                size="small"
              >
                <SwapsIcon fill="currentColor" className="h-5 w-5" /> View Swaps
              </LegacyButtonLink>
            );

          case 'remove':
            return (
              <span>
                <Button
                  key={action}
                  id={action}
                  className={buttonClasses}
                  color="danger"
                  onClick={() =>
                    modals.openModal('removeReport', {
                      item,
                      onSuccessfulSubmit: reloadItem,
                      type: 'removeItem',
                    })
                  }
                  variant="text"
                  size="small"
                >
                  <RemoveIcon fill="currentColor" className="h-5 w-5" /> Remove
                </Button>
              </span>
            );

          case 'take_down':
            return (
              <span>
                <Button
                  key={action}
                  id={action}
                  color="danger"
                  className={buttonClasses}
                  onClick={() => takeDownItem()}
                  disabled={takeDownStatus === 'loading'}
                  variant="text"
                  size="small"
                >
                  <RemoveIcon fill="currentColor" className="h-5 w-5" /> Take
                  Down
                </Button>
              </span>
            );
        }
      })}
    </>
  );

  return (
    <>
      <ErrorMessage
        errors={
          (takeDownError ||
            addToSaleError ||
            removeFromSaleError ||
            bumpItemError) as ApiError
        }
      />

      <div className="grid grid-cols-1 gap-2">
        {session?.decodedToken?.org_id && (
          <LegacyButtonLink
            as={<NewTabLink href={`/trade-in_listing_qr?item_id=${item.id}`} />}
            className={buttonClasses}
          >
            {/* Print Icon */}
            <PrinterIcon className="h-54 w-54" /> Print Warehouse Label
          </LegacyButtonLink>
        )}

        {item.actions.includes('bump') && (
          <Button
            id="bump"
            className={buttonClasses}
            onClick={() => bumpItem()}
            disabled={bumpItemStatus === 'loading'}
            size="large"
            variant="outlined"
          >
            <BumpIcon fill="currentColor" className="h-5 w-5" /> Bump
          </Button>
        )}

        <ProItemSwapInsights item={item} asChild>
          <Button
            id="insights"
            className={buttonClasses}
            size="large"
            variant="outlined"
          >
            <ChartBarIcon className="h-5 w-5" /> View Insights
          </Button>
        </ProItemSwapInsights>

        <div className="mt-4 grid grid-cols-3 gap-2">
          <LegacyButtonLink
            as={<ListFlowLink itemId={item.id} />}
            color="black"
            className={buttonClasses}
            variant="text"
            size="small"
          >
            <EditIcon fill="currentColor" className="h-5 w-5" /> Edit
          </LegacyButtonLink>

          <LegacyButtonLink
            as={<ListFlowLink src="item" duplicateId={item.id} />}
            className={buttonClasses}
            variant="text"
            size="small"
          >
            <DuplicateIcon fill="currentColor" className="h-5 w-5" /> Duplicate
          </LegacyButtonLink>

          <span>
            <Button
              onClick={() =>
                modals.openModal('priceDrop', {
                  item,
                  onSuccessfulSubmit: reloadItem,
                })
              }
              className={buttonClasses}
              variant="text"
              size="small"
            >
              <PriceDropIcon fill="currentColor" className="h-5 w-5" /> Price
              Drop
            </Button>
          </span>

          {additionalActions}
        </div>
      </div>
    </>
  );
};

export default ItemOwnActions;
